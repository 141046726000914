import React from 'react'
import { ScrollTo } from 'react-scroll-to'
import ScrollIndicator from 'react-scroll-sensor'
import { connect } from 'react-redux'
const divStyle = {
    transition: `opacity 500ms ease-in-out`,
    opacity: 0,
}
const transitionStyles = {
    end: { opacity: 1 },
    start: { opacity: 0 },
}
class MyScrollTo extends React.Component {
    render() {
        const { IsHungarian, lang_switch } = this.props
        const Texts = () => {
            switch (lang_switch) {
                case "HU":
                    return "Az oldal tetejére"
                case "DE":
                    return "Seitenanfang "
                case "GB":
                    return "Top of the page"
                case "ES":
                    return "Es"
    
                default:
                    return "Az oldal tetejére"
            }
        }
        return (
            <div className="content">
                <div className="container">
                    <div className="columns is-mobile is-centered has-text-centered">
                        <ScrollIndicator throttleDelay={300}>
                            {(scrollX, scrollY) => (
                                <ScrollTo>
                                    {({ scrollTo }) => (
                                        <div
                                            className="button upIcon"
                                            style={{
                                                ...divStyle,
                                                ...transitionStyles[
                                                scrollY > 1200
                                                    ? 'end'
                                                    : 'start'
                                                ],
                                            }}

                                            onClick={() =>
                                                scrollTo({ y: 0, smooth: true })
                                            }
                                        >
                                            <Texts />
                                        </div>
                                    )}
                                </ScrollTo>
                            )}
                        </ScrollIndicator>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    state => ({
        IsHungarian: state.app.IsHungarian,
        lang_switch: state.app.lang_switch
    }),
    null
)(MyScrollTo)
