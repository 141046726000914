import React from 'react'
import Fade from 'react-reveal/Fade'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import { connect } from 'react-redux'
const divStyle = {
    backgroundColor: 'white',

    paddingBottom: '10em',
    marginBottom: 0,
    color: 'white',
}

const buttonStyle = {
    backgroundColor: 'rgb(66, 103, 178)',
    borderRadius: 0,
    border: 0,
    color: 'white',
    padding: '2px',
    fontSize: '0.8rem'
}
const isMobile = false
const facebookUrl = 'https://www.facebook.com/maliviktoria/'
class Connect extends React.Component {
    
    componentDidMount() {
    }
    render() {
        const { IsHungarian, lang_switch } = this.props
        const Or = () => {
            switch (lang_switch) {
                case "HU":
                    return " vagy "
                case "DE":
                    return " oder "
                case "GB":
                    return " or "
                case "ES":
                    return " es "
    
                default:
                    return this.props.title
            }
        }
        return (
            <div
                id="connect"
                className="content has-text-centered "
                style={{ ...divStyle }}
            >
                {console.log(isMobile)}
                <div className="container">
                    <div className="columns connectImage">
                        <div className="column is-4" />
                        <div className="column is-4">
                            <Fade bottom>
                                
                                <PreviewCompatibleImage
                                                    image={this.props.profilimage}
                                                />
                                <address>
                                    
                                    <br />
                                    Email: <br />
                                    <a href={'mailto:' + this.props.email}>
                                        {this.props.email}
                                    </a>
                                    <br />
                                    Telefon:
                                    {isMobile?<a href={"tel:"+this.props.phone}>{this.props.phone}</a> :
                                    this.props.phone}
                                    <br />
                                    <a
                                        target="_blank"
                                        href={facebookUrl}
                                        style={{ ...buttonStyle }}
                                    >
                                        Facebook
                                    </a>
                                </address>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    state => ({
        IsHungarian: state.app.IsHungarian,
        lang_switch: state.app.lang_switch
    }),
    null
)(Connect)
