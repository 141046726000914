import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import Fade from 'react-reveal/Fade'
import MyTable from './MyTable'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import { connect } from 'react-redux'
const divStyle = {
    margin: '0',
    paddingTop: '0em',
    color: 'black',
}
const columnStyle = {
    paddingTop: '10vh',
    paddingBottom: '10vh',
    marginBottom: 0,
}
class TableRoll extends React.Component {

    render() {
        const { IsHungarian, lang_switch } = this.props
        const posts = this.props.table
        const Header = () => {
            switch (lang_switch) {
                case "HU":
                    return "Órák"
                case "DE":
                    return "Stunden"
                case "GB":
                    return "Lessons"
                case "ES":
                    return "Es"

                default:
                    return "Órák"
            }
        }

        let array = []
        let array2 = []
        posts.map(map => {
            switch (lang_switch) {
                case "HU":
                    if (!array.includes(map.node.frontmatter.hu.tags)) {
                        array.push(map.node.frontmatter.hu.tags)
                    }
                    return array
                case "DE":
                    if (!array.includes(map.node.frontmatter.de.tags)) {
                        array.push(map.node.frontmatter.de.tags)
                    }
                    return array
                case "ES":
                    if (!array.includes(map.node.frontmatter.hu.tags)) {
                        array.push(map.node.frontmatter.hu.tags)
                    }
                    return array

                default:
                    if (!array.includes(map.node.frontmatter.hu.tags)) {
                        array.push(map.node.frontmatter.hu.tags)
                    }
                    return array
            }


        })
        posts.map(map => {
            switch (lang_switch) {
                case "HU":
                    if (array2[map.node.frontmatter.hu.tags] === undefined) {
                        array2[map.node.frontmatter.hu.tags] = [[map.node.frontmatter.hu]]
                    } else {
                        array2[map.node.frontmatter.hu.tags].push([
                            map.node.frontmatter.hu,
                        ])
                    }
                    return array2
                case "DE":
                    if (array2[map.node.frontmatter.de.tags] === undefined) {
                        array2[map.node.frontmatter.de.tags] = [[map.node.frontmatter.de]]
                    } else {
                        array2[map.node.frontmatter.de.tags].push([
                            map.node.frontmatter.de,
                        ])
                    }
                    return array2

                case "ES":
                    if (array2[map.node.frontmatter.hu.tags] === undefined) {
                        array2[map.node.frontmatter.hu.tags] = [[map.node.frontmatter.hu]]
                    } else {
                        array2[map.node.frontmatter.hu.tags].push([
                            map.node.frontmatter.hu,
                        ])
                    }
                    return array2
                default:
                    if (array2[map.node.frontmatter.hu.tags] === undefined) {
                        array2[map.node.frontmatter.hu.tags] = [[map.node.frontmatter.hu]]
                    } else {
                        array2[map.node.frontmatter.hu.tags].push([
                            map.node.frontmatter.hu,
                        ])
                    }
                    return array2
            }


        })
        return (
            <div
                className="content has-text-centered"
                id="lessons"
                style={{ ...divStyle }}
            >
                <div className="container">
                    <div className="columns" style={{ ...columnStyle }}>
                        <div className="column is-10 is-offset-1">
                            <Fade bottom>
                                <h2><Header /></h2>

                                <Tabs>
                                    <TabList>
                                        {array.map(link => (
                                           <Tab><h3> {link}</h3> </Tab>
                                        ))}
                                    </TabList>
                                    {console.log(array2)}
                                    {Object.keys(array2).map(b => (
                                        <TabPanel>

                                            {array2[b].map(v => (
                                                <MyTable
                                                    title={v[0].title }
                                                    thead={v[0].thead }
                                                    tbody={v[0].tbody }
                                                    text={ v[0].text }
                                                />
                                            ))}
                                        </TabPanel>
                                    ))}
                                </Tabs>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}
TableRoll.propTypes = {
    IsHungarian: PropTypes.string,
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            edges: PropTypes.array,
        }),
    }),
}


export default connect(
    state => ({
        IsHungarian: state.app.IsHungarian,
        lang_switch: state.app.lang_switch
    }),
    null
)(TableRoll)