import React from 'react'
import { ScrollTo } from 'react-scroll-to'
import ScrollIndicator from 'react-scroll-sensor'
import { connect } from 'react-redux'
const divStyle = {
    transition: `opacity 500ms ease-in-out`,
    opacity: 0,
}
const transitionStyles = {
    end: { opacity: 1 },
    start: { opacity: 0 },
}
class MyScrollToDown extends React.Component {
    constructor(props) {
        super(props)
        this.state = { wh: 0 }
    }
    componentDidMount() {
        this.setState({ wh: window.innerHeight })
    }
    render() {
        const { IsHungarian, lang_switch } = this.props
        const Texts = () => {
            switch (lang_switch) {
                case "HU":
                    return "Tovább"
                case "DE":
                    return "Weiter"
                case "GB":
                    return "Further"
                case "ES":
                    return "Es"
    
                default:
                    return "Tovább"
            }
        }
        return (
            <div className="content">
                <div className="container">
                    <div className="columns is-mobile is-centered has-text-centered">
                        <ScrollIndicator throttleDelay={300}>
                            {(scrollX, scrollY) => (
                                <ScrollTo>
                                    {({ scrollTo }) => (
                                        <p
                                            className="button upIcon"
                                            style={{
                                                ...divStyle,
                                                ...transitionStyles[
                                                    scrollY < this.state.wh
                                                        ? 'end'
                                                        : 'start'
                                                ],
                                            }}
                                            
                                            onClick={() =>
                                                scrollTo({
                                                    y: window.innerHeight,
                                                    smooth: true,
                                                })
                                            }
                                        >
                                           <Texts />
                                        </p>
                                    )}
                                </ScrollTo>
                            )}
                        </ScrollIndicator>
                    </div>
                </div>
            </div>
        )
    }
}
 
export default connect(
    state => ({
        IsHungarian: state.app.IsHungarian,
        lang_switch: state.app.lang_switch
    }),
    null
)(MyScrollToDown)
