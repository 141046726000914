import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import About from '../components/About'
import Fade from 'react-reveal/Fade'
import Connect from '../components/Connect'
import Layout from '../components/Layout'
import BlogRoll from '../components/BlogRoll'
import TableRoll from '../components/TableRoll'
import { toggleDarkMode, selectLang } from '../state/app'
import { connect, useDispatch } from 'react-redux'
import Footer from '../components/Footer2'
import Navbar from '../components/Navbar'
import MyScrollTo from '../components/MyScrollTo'
import MyScrollToDown from '../components/MyScrollToDown'
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/scss/react-flags-select.scss';
import ReactCountryFlag from "react-country-flag";
import MyParallax from '../components/MyParallax'
import CookieConsent from "react-cookie-consent";
import { OutboundLink } from 'gatsby-plugin-google-analytics'
export const IndexPageTemplate = ({
    image,
    title,
    subheading,
    about,
    connects,
    blogs,
    table,
    isHungarian,
    langSwitch
}) => {
    return (
        <div>
            <div
                className="full-width-image margin-top-0 landing-image has-text-centered"
                style={{
                    backgroundImage: `url(${!!image.childImageSharp ? image.childImageSharp.fluid.src : image})`,
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        height: '150px',
                        lineHeight: '1',
                        justifyContent: 'space-around',
                        alignItems: 'left',
                        flexDirection: 'column',
                    }}
                >
                    <Fade bottom>
                        <h1
                            className=" is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
                            style={{
                                color: 'black',
                                lineHeight: '1',
                                padding: '0.25em',
                            }}
                        >
                            {title}<a hidden>{subheading}</a>
                        </h1>
                    </Fade>
                </div>
            </div>
            <div className="background_fill" />
            <MyScrollTo />
            <MyScrollToDown />
            <About
                gertitle={about.gertitle}
                entitle={about.entitle}
                title={about.title}
                aboutimage={about.aboutimage}
                alt={about.alt}
                description={about.description}
                gerdescription={about.gerdescription}
                endescription={about.endescription}
            />
            <BlogRoll blogs={blogs}  />
            <TableRoll table={table} />
            <Connect
                profilimage={connects.profilimage}
                title={connects.title}
                email={connects.email}
                phone={connects.phone}
            />
            <CookieConsent
                location="bottom"
                buttonText="Rendben!"
                cookieName="myAwesomeCookieName2"
                style={{ background: "#2B373B" }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={1}
            >
                A weboldal sütiket használ. Az Uniós törvények értelmében kérlek, engedélyezd a sütik használatát.
                
            </CookieConsent>
            <Footer />
        </div>
    )
}
IndexPageTemplate.propTypes = {
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    title: PropTypes.string,
    subheading: PropTypes.string,
    menu2: PropTypes.array,
    connects: PropTypes.shape({
        profilimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    }),
    about: PropTypes.shape({
        gertitle: PropTypes.string, 
        entitle: PropTypes.string,
        gerdescription: PropTypes.string,
        endescription: PropTypes.string,
        title: PropTypes.string,
        aboutimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        tag: PropTypes.string,
        message: PropTypes.string,
    }),
}
const IndexPage = ({ data, lang_switch }) => {
    const dispatch = useDispatch()
    const { image, title, subheading, about, menu, menu2, connects } = data.index.frontmatter
    const { blogs } = data
    const { edges: posts } = data.table
    const Flags = <ReactCountryFlag
        styleProps={{
            width: '2rem',
            height: '2rem'
        }}
        code={"hu"} svg />
    function onSelectFlag(countryCode) {
        dispatch(selectLang(countryCode))
    }
    return (
        <Layout menu={menu}>
            <div class="columns mainNav">
                <div
                    className="column"
                    style={{ paddingBottom: '0em', position: 'fixed', zIndex: '100' }}
                >
                    <Fade top>
                        <div class="field">
                            <ReactFlagsSelect
                                defaultCountry="HU"
                                onSelect={onSelectFlag}
                                showSelectedLabel={false}
                                countries={["HU",  "DE"]}
                                customLabels={{ "GB": "English", "HU": "Magyar", "DE": "Deutsch", "ES": "Español" }}
                                selectedSize={18}
                                optionsSize={14}
                            />
                        </div>
                    </Fade>

                </div>
                <div
                    className="column  "
                    style={{ paddingBottom: '0em' }}
                >
                    <Navbar menu={menu2} />
                </div>
            </div>
            <IndexPageTemplate
                image={image}
                title={title}
                subheading={subheading}
                about={about}
                menu={menu}
                connects={connects}
                blogs={blogs}
                table={posts}
                lang_switch={lang_switch}
            />
        </Layout >
    )
}
IndexPage.propTypes = {
    menu: PropTypes.object,
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.object,
        }),
    }),
}
export default connect(
    state => ({
        lang_switch: state.app.lang_switch
    }),
    null
)(IndexPage)
export const pageQuery = graphql`
     {
        index:  markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
            frontmatter {
                title
                image {
                        
                    childImageSharp {
                        fluid(
							maxWidth: 1024
							quality: 100
							srcSetBreakpoints: [
								100
								200
								300
								400
								500
								600
								720
								840
								960
								1200
								1400
								1600
								2000
							]
						) {
							...GatsbyImageSharpFluid
						}
                    }
                  
                }
                heading
                subheading
                menu2
                menu {
                    about {
                        id
                        hu
                        de
                    }
                    gallery {
                        id
                        hu
                        de
                    }
                    lessons {
                        id
                        hu
                        de
                    }
                    connect {
                        id
                        hu
                        de
                    }
                }
                about {
                    title
                    gertitle
                    entitle
                    aboutimage {
                        
                        childImageSharp {
                            fluid(
                                maxWidth: 1024
                                quality: 100
                                srcSetBreakpoints: [
                                    100
                                    200
                                    300
                                    400
                                    500
                                    600
                                    720
                                    840
                                    960
                                    1200
                                    1400
                                    1600
                                    2000
                                ]
                            ) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                      
                    }
                    alt
                    description
                    gerdescription
                    endescription
                }
                connects {
                    title
                    email
                    phone
                    profilimage{
                        
                        childImageSharp {
                            fluid(
                                maxWidth: 1024
                                quality: 100
                                srcSetBreakpoints: [
                                    100
                                    200
                                    300
                                    400
                                    500
                                    600
                                    720
                                    840
                                    960
                                    1200
                                    1400
                                    1600
                                    2000
                                ]
                            ) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                      
                    }
                }
            }
        }
    
    
    
        blogs: allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: {
                frontmatter: { templateKey: { eq: "blog-post" } }
            }
        ) {
            edges {
                node {
                    excerpt(pruneLength: 400)
                    id
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        gertitle
                        entitle
                        templateKey
                        date(formatString: "MMMM DD, YYYY")
                        featuredimage{
                            image {
                                childImageSharp {
                                    fluid(
                                        maxWidth: 1024
                                        quality: 100
                                        srcSetBreakpoints: [
                                            100
                                            200
                                            300
                                            400
                                            500
                                            600
                                            720
                                            840
                                            960
                                            1200
                                            1400
                                            1600
                                            2000
                                        ]
                                    ) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                              }
                        }
                        
                    }
                }
            }
        }
        table:  allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: {
                frontmatter: { templateKey: { eq: "table-page" } }
            }
        ) {
            edges {
                node {
                    excerpt(pruneLength: 400)
                    id
                    fields {
                        slug
                    }
                    frontmatter {
                        templateKey
                        date(formatString: "MMMM DD, YYYY")
                        hu {
                            title
                            tags
                            thead
                            tbody
                            text
                        }
                        de {
                            title
                            tags
                            thead
                            tbody
                            text
                        }
                        
                    }
                }
            }
        }
    }
`
