import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import Fade from 'react-reveal/Fade'
import LightBox from '../components/LightBox'
import { connect } from 'react-redux'
const divStyle = {
    margin: '0',
    paddingTop: '0em',
    color: 'white',
}
const columnStyle = {
    paddingTop: '10vh',
    paddingBottom: '10vh',
    marginBottom: 0,
}
class BlogRoll extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            items: 'hu-HU',
        }
    }
    render() {
        const { data, IsHungarian, lang_switch } = this.props
        const { edges: posts } = data.allMarkdownRemark
        console.log(posts[0].node.frontmatter.entitle)
        const Title = () => {
            switch (lang_switch) {
                case "HU":
                    return posts[0].node.frontmatter.title
                case "DE":
                    return posts[0].node.frontmatter.gertitle
                case "GB":
                    return posts[0].node.frontmatter.entitle
                case "ES":
                    return "Es"

                default:
                    return posts[0].node.frontmatter.title
            }
        }
        const Header = () => {
            switch (lang_switch) {
                case "HU":
                    return "Galéria"
                case "DE":
                    return "Galerie"
                case "GB":
                    return "Gallery"
                case "ES":
                    return "Es"

                default:
                    return "Galéria"
            }
        }
        return (
            <div
                className="content has-text-centered"
                id="gallery"
                style={{ ...divStyle }}
            >
                <div className="container">
                    <div className="columns" style={{ ...columnStyle }}>
                        <div className="column is-10 is-offset-1">
                            <Fade bottom>

                            </Fade>
                            {posts &&
                                posts.map(({ node: post }) => (
                                    <div key={post.id}>
                                        {post.frontmatter.featuredimage ? (
                                            <div>
                                                <p className="post-meta">


                                                    <p
                                                        className="title  is-size-4"

                                                    >

                                                        <Fade bottom>

                                                            <h2><Title /></h2>
                                                        </Fade>
                                                    </p>

                                                </p>
                                                <Fade bottom>
                                                    <PreviewCompatibleImage
                                                        image={post.frontmatter.featuredimage}
                                                    />


                                                    <LightBox images={post.frontmatter.featuredimage} />
                                                </Fade>
                                            </div>
                                        ) : null}
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
BlogRoll.propTypes = {
    IsHungarian: PropTypes.string,
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            edges: PropTypes.array,
        }),
    }),
}
const ConnectedDefaultLayout = connect(
    state => ({
        IsHungarian: state.app.IsHungarian,
        lang_switch: state.app.lang_switch
    }),
    null
)(BlogRoll)
export default () => (
    <StaticQuery
        query={graphql`
            query BlogRollQuery {
                allMarkdownRemark(
                    sort: { order: DESC, fields: [frontmatter___date] }
                    filter: {
                        frontmatter: { templateKey: { eq: "blog-post" } }
                    }
                ) {
                    edges {
                        node {
                            excerpt(pruneLength: 400)
                            id
                            fields {
                                slug
                            }
                            frontmatter {
                                title
                                gertitle
                                entitle
                                templateKey
                                date(formatString: "MMMM DD, YYYY")
                                featuredimage{
                                    image {
                                        childImageSharp {
                                          fluid(maxWidth: 800, quality: 80) {
                                            ...GatsbyImageSharpFluid_withWebp
                                          }
                                        }
                                      }
                                }
                                
                            }
                        }
                    }
                }
            }
        `}
        render={data => <ConnectedDefaultLayout data={data} />}
    />
)
