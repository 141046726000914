import React from 'react'
import Fade from 'react-reveal/Fade'
import { connect } from 'react-redux'
import PreviewCompatibleImage from './PreviewCompatibleImage'

const titleStyle = {
    fontSize: '2rem'
}
const divStyle = {
    margin: '0',
    paddingTop: '0em',
    color: '#444',
}
const columnStyle = {
    paddingTop: '10vh',
    paddingBottom: '10vh',
}
class About extends React.Component {
    render() {
        const {  lang_switch } = this.props
        const Header = () => {
            switch (lang_switch) {
                case "HU":
                    return <p>Kedves Látogató!</p>
                case "DE":
                    return <p>Liebe Besucherin, lieber Besucher!</p>
                case "GB":
                    return <p>Dear guests</p>
                case "ES":
                    return <p>ES</p>
    
                default:
                    return <p>Kedves Látogató!</p>
            }
        }
        const Title = () => {
            switch (lang_switch) {
                case "HU":
                    return this.props.title
                case "DE":
                    return this.props.gertitle
                case "GB":
                    return this.props.entitle
                case "ES":
                    return "ES"
    
                default:
                    return this.props.title
            }
        }
        const Description = () => {
            switch (lang_switch) {
                case "HU":
                    return this.props.description
                case "DE":
                    return this.props.gerdescription
                case "GB":
                    return this.props.endescription
                case "ES":
                    return "ES"
    
                default:
                    return this.props.description
            }
        }
        return (
            <div
                id="about"
                className="content has-text-centered"
                style={{ ...divStyle }}
            >
                <div className="container">
                    <div className="columns" style={{ ...columnStyle }}>
                        <div className="column is-half is-offset-one-quarter">
                            <Fade bottom>
                                <p style={{...titleStyle}}><Title /></p>
                            </Fade>
                            <div>
                                <div>
                                    <Fade bottom>
                                        
                                    <PreviewCompatibleImage
                                                    image={this.props.aboutimage}
                                                />
                                    </Fade>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <Fade bottom>
                                        <h2><Header /></h2>
                                    </Fade>
                                    <Fade left>
                                        <Description />
                                    </Fade>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default connect(
    state => ({
        lang_switch: state.app.lang_switch
    }),
    null
)(About)
