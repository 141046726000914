import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-scroll';
import { Link as GatsbyLink } from 'gatsby';
import Fade from 'react-reveal/Fade';
const Navbar = class extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			active: false,
			navBarActiveClass: '',
		};
	}
	toggleHamburger = () => {
		// toggle the active boolean in the state
		this.setState(
			{
				active: !this.state.active,
			},
			// after state has been updated,
			() => {
				// set the class in state for the navbar accordingly
				this.state.active
					? this.setState({
							navBarActiveClass: 'is-active',
					  })
					: this.setState({
							navBarActiveClass: '',
					  });
			},
		);
	};
	render() {
		const { lang_switch } = this.props;
		const Links = ({ data, to }) => (
            to === "quiz" ? 
            <a
				href="http://maliviki.atwebpages.com/"
                className="navbar-item"
                target="_blank"
			>
				{data}
			</a>: 
			<Link
				to={to}
				className="navbar-item"
				spy={true}
				smooth={true}
				duration={500}
			>
				{data}
			</Link>
		);
		const Flags = ({ props }) => {
			switch (lang_switch) {
				case 'HU':
					return <Links data={props[1]} to={props[0]} />;
				case 'DE':
					return <Links data={props[2]} to={props[0]} />;
				case 'GB':
					return <Links data={props[3]} to={props[0]} />;
				case 'ES':
					return <Links data={props[4]} to={props[0]} />;

				default:
					return <Links data={props[1]} to={props[0]} />;
			}
		};
		return (
			<Fade top>
				<nav
					className="navbar is-transparent"
					role="navigation"
					aria-label="main-navigation"
				>
					<div className="container level">
						<div
							className="columns level-right is-centered"
							style={{
								right: 0,
								display: 'block',
								width: '100%',
							}}
						>
							<div
								className="column"
								style={{
									margin: 0,
									padding: 0,
								}}
							>
								<div className="navbar-brand ">
									{/* Hamburger menu */}
									<div
										className={`navbar-burger burger  ${
											this.state.navBarActiveClass
										}`}
										data-target="navMenu"
										onClick={() => this.toggleHamburger()}
									>
										<span />
										<span />
										<span />
									</div>
								</div>
							</div>
							<div className="column " style={{}}>
								<div
									id="navMenu"
									className={`navbar-menu is-centered ${
										this.state.navBarActiveClass
									}`}
								>
									<div className="navbar-start " style={{ width: '100%' }}>
										{typeof this.props.menu != 'undefined'
											? this.props.menu.map((menuItem) => (
													<Flags props={menuItem} />
											  ))
											: null}
									</div>
								</div>
							</div>
						</div>
					</div>
				</nav>
			</Fade>
		);
	}
};
export default connect(
	(state) => ({
		lang_switch: state.app.lang_switch,
	}),
	null,
)(Navbar);
